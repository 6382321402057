import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mx-auto max-w-4xl p-4" }
const _hoisted_2 = { class: "text-center mb-6" }
const _hoisted_3 = { class: "font-londrina font-yusei text-xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DailyRecordChart = _resolveComponent("DailyRecordChart")!
  const _component_MonthlyRecordChart = _resolveComponent("MonthlyRecordChart")!
  const _component_OwnerRanking = _resolveComponent("OwnerRanking")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("statistics.description")), 1)
    ]),
    _createVNode(_component_DailyRecordChart, {
      network: _ctx.network,
      tokenAddress: _ctx.tokenAddress
    }, null, 8, ["network", "tokenAddress"]),
    _createVNode(_component_MonthlyRecordChart, {
      network: _ctx.network,
      tokenAddress: _ctx.tokenAddress
    }, null, 8, ["network", "tokenAddress"]),
    _createVNode(_component_OwnerRanking, {
      network: _ctx.network,
      tokenAddress: _ctx.tokenAddress
    }, null, 8, ["network", "tokenAddress"])
  ]))
}