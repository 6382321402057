import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "layout" }
const _hoisted_2 = {
  key: 0,
  id: "nav",
  class: "bg-gray-200"
}
const _hoisted_3 = {
  key: 1,
  class: "flex justify-end"
}
const _hoisted_4 = { key: 2 }
const _hoisted_5 = {
  key: 3,
  class: "font-londrina font-yusei text-xl"
}
const _hoisted_6 = {
  id: "nav",
  class: "mt-200 bg-gray-200"
}
const _hoisted_7 = { id: "nav" }
const _hoisted_8 = ["href"]
const _hoisted_9 = ["href"]
const _hoisted_10 = ["href"]
const _hoisted_11 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_Languages = _resolveComponent("Languages")!
  const _component_Connect = _resolveComponent("Connect")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.displayMenu)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_router_link, {
            to: _ctx.localizedUrl('/'),
            class: "font-londrina font-yusei text-xl"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("nav.top")), 1)
            ]),
            _: 1
          }, 8, ["to"]),
          _createTextVNode(" | "),
          _createVNode(_component_router_link, {
            to: _ctx.localizedUrl('/mint'),
            class: "font-londrina font-yusei text-xl"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("nav.mint")), 1)
            ]),
            _: 1
          }, 8, ["to"]),
          _createTextVNode(" | "),
          _createVNode(_component_router_link, {
            to: _ctx.localizedUrl('/list/0'),
            class: "font-londrina font-yusei text-xl"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("nav.list")), 1)
            ]),
            _: 1
          }, 8, ["to"]),
          _createTextVNode(" | "),
          _createVNode(_component_router_link, {
            to: _ctx.localizedUrl('/statistics'),
            class: "font-londrina font-yusei text-xl"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("nav.statistics")), 1)
            ]),
            _: 1
          }, 8, ["to"]),
          _createVNode(_component_Languages, { class: "mt-4" }),
          (_ctx.displayWallet)
            ? (_openBlock(), _createBlock(_component_Connect, {
                key: 0,
                network: _ctx.network
              }, null, 8, ["network"]))
            : _createCommentVNode("", true)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Languages, { class: "mt-4 mb-2 mr-4" })
        ])),
    (_ctx.networkChainId == _ctx.chainId || !_ctx.chainId)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_router_view)
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(_ctx.$t("menu.switchNetwork")), 1)),
    _createElementVNode("div", _hoisted_6, [
      _createVNode(_component_router_link, {
        to: _ctx.localizedUrl('/explanation'),
        class: "font-londrina font-yusei"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("nav.explanation")), 1)
        ]),
        _: 1
      }, 8, ["to"]),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, {
        to: _ctx.localizedUrl('/terms'),
        class: "font-londrina font-yusei"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("nav.terms")), 1)
        ]),
        _: 1
      }, 8, ["to"]),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, {
        to: _ctx.localizedUrl('/tokushoho'),
        class: "font-londrina font-yusei"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("nav.tokushoho")), 1)
        ]),
        _: 1
      }, 8, ["to"]),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, {
        to: _ctx.localizedUrl('/privacy'),
        class: "font-londrina font-yusei"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("nav.privacy")), 1)
        ]),
        _: 1
      }, 8, ["to"]),
      _createTextVNode(" | "),
      _createVNode(_component_router_link, {
        to: _ctx.localizedUrl('/about'),
        class: "font-londrina font-yusei"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("nav.about")), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("a", {
        href: _ctx.TWITTER_URL,
        target: "_blank",
        class: "font-londrina font-yusei text-sm"
      }, "X(Twitter)", 8, _hoisted_8),
      _createTextVNode(" | "),
      _createElementVNode("a", {
        href: _ctx.DISCORD_URL,
        target: "_blank",
        class: "font-londrina font-yusei text-sm"
      }, "discord", 8, _hoisted_9),
      _createTextVNode(" | "),
      _createElementVNode("a", {
        href: _ctx.OpenSeaPath,
        target: "_blank",
        class: "font-londrina font-yusei text-sm"
      }, "Opensea", 8, _hoisted_10),
      _createTextVNode(" | "),
      _createElementVNode("a", {
        href: _ctx.EtherscanToken,
        target: "_blank",
        class: "font-londrina font-yusei text-sm"
      }, "Etherscan", 8, _hoisted_11)
    ])
  ]))
}