
import { defineComponent, computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import TokenDetail from "@/components/TokenDetail.vue";
import NounsMap from "@/components/NounsMap.vue";
import { TOKEN } from "@/firestore/const";
import { createdTime, tokenInfo_ja, tokenInfo_en } from "@/utils/allNouns";

export default defineComponent({
  name: "AllNouns",
  components: {
    TokenDetail,
    NounsMap,
  },
  async setup() {
    const i18n = useI18n();

    const lang = computed(() => {
      return i18n.locale.value;
    });

    // langが変更されたらgetTokenListを実行するウォッチャー
    watch(lang, async (newLang, oldLang) => {
      if (newLang !== oldLang) {
        getTokenList();
      }
    });

    const displayTime = ref("");

    const tokens = ref<TOKEN[]>([]);
    const getTokenList = async () => {
      if (lang.value == "ja") {
        tokens.value = tokenInfo_ja;
        displayTime.value = new Intl.DateTimeFormat("ja-JP").format(
          new Date(createdTime),
        );
      } else {
        tokens.value = tokenInfo_en;
        displayTime.value = new Intl.DateTimeFormat("en-US").format(
          new Date(createdTime),
        );
      }
    };
    getTokenList();

    // トークンを都道府県IDでグループ化するcomputedプロパティ
    const groupedByPrefecture = computed(() => {
      // 最初にtokensをtokenIdでソート
      const sortedTokens = [...tokens.value].sort((a, b) => {
        return Number(a.tokenId) - Number(b.tokenId);
      });

      const groups = sortedTokens.reduce((acc: any, token) => {
        // prefectureIdをキーとする
        const key = token.prefectureId;
        if (!acc[key]) {
          // キーが存在しなければ、新しい配列を作成
          acc[key] = [];
        }
        // トークンをキーに対応する配列に追加
        acc[key].push(token);
        return acc;
      }, {}); // 初期値は空のオブジェクト
      return groups;
    });

    return {
      lang,
      groupedByPrefecture,
      tokens,
      getTokenList,
      displayTime,
    };
  },
});
