
import { defineComponent, ref, PropType } from "vue";
import { TOKEN } from "@/firestore/const";

export default defineComponent({
  props: {
    myTokens: {
      type: Array as PropType<TOKEN[]>,
      required: true,
    },
    tradeForPrefectures: {
      type: Array,
      required: true,
    },
  },
  setup(props, context) {
    const selectedValue = ref("");
    const hasTradableNouns = ref(false);
    if (props.tradeForPrefectures[0] == "NotSpecified") {
      props.myTokens.forEach((token) => {
        token.canTrade = true;
        hasTradableNouns.value = true;
      });
    } else {
      props.myTokens.forEach((token) => {
        token.canTrade = props.tradeForPrefectures.includes(token.prefecture);
        if (token.canTrade) {
          hasTradableNouns.value = true;
        }
      });
    }

    const updateValue = (
      event: { target: HTMLSelectElement },
      tokenId: string,
    ) => {
      if (event.target.value) {
        selectedValue.value = tokenId;
        console.log("MyNounsRadioButton:updateValue", tokenId);
        context.emit("updateValues", selectedValue.value);
      }
    };
    return {
      selectedValue,
      updateValue,
      hasTradableNouns,
    };
  },
});
