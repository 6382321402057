
import { defineComponent, ref } from "vue";

export default defineComponent({
  setup(props, context) {
    const filterType = ["prefecture", "onSale", "onTrade", "myNouns", "admins"];
    const selectedValue = ref(filterType[0]);

    const updateValue = (event: { target: HTMLSelectElement }) => {
      context.emit("update:modelValue", event.target.value);
    };
    return {
      selectedValue,
      filterType,
      updateValue,
    };
  },
});
