import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_GChart = _resolveComponent("GChart")!

  return (_openBlock(), _createBlock(_component_GChart, {
    type: "GeoChart",
    data: _ctx.chartData,
    options: _ctx.chartOptions,
    settings: _ctx.chartSettings,
    events: _ctx.chartEvents
  }, null, 8, ["data", "options", "settings", "events"]))
}