
import { defineComponent } from "vue";
import { TOKEN } from "@/firestore/const";
import { getAddresses } from "@/utils/const";
import { prefectureList } from "@/i18n/prefectures";

export default defineComponent({
  props: {
    token: {
      type: Object as () => TOKEN,
      required: true,
    },
    size: {
      type: String,
      required: false,
    },
    network: {
      type: String,
      required: false,
    },
    tokenAddress: {
      type: String,
      required: false,
    },
  },
  name: "TokenDetail",
  setup(props) {
    let OpenSeaPath = "";
    const strNetwork: string = props.network ? props.network : "";
    const strTokenAddress: string = props.tokenAddress
      ? props.tokenAddress
      : "";
    if (strNetwork && strTokenAddress) {
      const { OpenSeaBase } = getAddresses(strNetwork, strTokenAddress);
      OpenSeaPath =
        OpenSeaBase + "/" + props.tokenAddress + "/" + props.token.tokenId;
    }
    const imagePath = "/images/" + props.token.tokenId + ".svg";

    return {
      OpenSeaPath,
      prefectureList,
      imagePath,
    };
  },
});
