import { openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex flex-col justify-center items-center"
}
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = { class: "mb-2 font-yusei text-3xl" }
const _hoisted_4 = { class: "mb-2 font-londrina font-yusei text-2xl relative mx-4" }
const _hoisted_5 = { class: "mb-2 font-londrina font-yusei relative text-left mx-4" }
const _hoisted_6 = { class: "mb-2 font-londrina font-yusei text-2xl relative mx-4" }
const _hoisted_7 = { class: "mb-2 font-londrina font-yusei relative text-left mx-4" }
const _hoisted_8 = {
  key: 1,
  class: "flex flex-col justify-center items-center"
}
const _hoisted_9 = ["innerHTML"]
const _hoisted_10 = { class: "mb-0 font-londrina font-yusei text-xl" }
const _hoisted_11 = { class: "mb-0 font-londrina font-yusei" }
const _hoisted_12 = { class: "mb-2 font-londrina font-yusei" }
const _hoisted_13 = {
  key: 2,
  class: "flex flex-col justify-center items-center"
}
const _hoisted_14 = ["src"]
const _hoisted_15 = { class: "mb-0 font-londrina font-yusei text-xl" }
const _hoisted_16 = { class: "mb-0 font-londrina font-yusei" }
const _hoisted_17 = { class: "mb-2 font-londrina font-yusei" }
const _hoisted_18 = {
  key: 3,
  class: "flex justify-center gap-2 w-full font-londrina font-yusei text-xl"
}
const _hoisted_19 = {
  key: 0,
  class: "inline-block rounded bg-red-500 w-20 px-1 py-2.5 leading-tight text-white shadow-md transition duration-150 mx-1 my-2"
}
const _hoisted_20 = {
  key: 1,
  class: "inline-block rounded bg-gray-500 w-20 px-1 py-2.5 leading-tight text-white shadow-md transition duration-150 mx-1 my-2"
}
const _hoisted_21 = {
  key: 0,
  class: "inline-block rounded bg-blue-500 w-20 px-1 py-2.5 leading-tight text-white shadow-md transition duration-150 mx-1 my-2"
}
const _hoisted_22 = {
  key: 1,
  class: "inline-block rounded bg-gray-500 w-20 px-1 py-2.5 leading-tight text-white shadow-md transition duration-150 mx-1 my-2"
}
const _hoisted_23 = {
  key: 4,
  class: "mb-2 font-londrina font-yusei text-xl"
}
const _hoisted_24 = {
  key: 5,
  class: "mb-2 font-londrina font-yusei text-sm"
}
const _hoisted_25 = { class: "text-center" }
const _hoisted_26 = { key: 0 }
const _hoisted_27 = { class: "mb-2 font-londrina font-yusei" }
const _hoisted_28 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (_ctx.size == 'L')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", null, [
              (_openBlock(), _createElementBlock("svg", {
                innerHTML: _ctx.token.svg,
                style: {"width":"320px","height":"320px"},
                "mx-4": ""
              }, null, 8, _hoisted_2))
            ]),
            _createElementVNode("div", _hoisted_3, " #" + _toDisplayString(_ctx.token.tokenId) + ", " + _toDisplayString(_ctx.$t("prefecture." + _ctx.token.prefecture)), 1),
            _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.token.head), 1),
            _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.token.headDescription), 1),
            _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.token.accessory), 1),
            _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.token.accessoryDescription), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.size == 'S')
        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
            _createElementVNode("div", null, [
              (_openBlock(), _createElementBlock("svg", {
                innerHTML: _ctx.token.svg,
                style: {"width":"100%","height":"auto"}
              }, null, 8, _hoisted_9))
            ]),
            _createElementVNode("div", _hoisted_10, " #" + _toDisplayString(_ctx.token.tokenId) + ", " + _toDisplayString(_ctx.$t("prefecture." + _ctx.token.prefecture)), 1),
            _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.token.head), 1),
            _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.token.accessory), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.size == 'SS')
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createElementVNode("div", null, [
              _createElementVNode("img", {
                src: _ctx.imagePath,
                style: {"width":"100%","height":"auto"}
              }, null, 8, _hoisted_14)
            ]),
            _createElementVNode("div", _hoisted_15, " #" + _toDisplayString(_ctx.token.tokenId) + ", " + _toDisplayString(_ctx.$t("prefecture." + _ctx.token.prefecture)), 1),
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.token.head), 1),
            _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.token.accessory), 1)
          ]))
        : _createCommentVNode("", true),
      (_ctx.size != 'SS')
        ? (_openBlock(), _createElementBlock("div", _hoisted_18, [
            _createElementVNode("div", null, [
              (_ctx.token.salePrice > 0)
                ? (_openBlock(), _createElementBlock("button", _hoisted_19, _toDisplayString(_ctx.$t("list.onSale")), 1))
                : (_openBlock(), _createElementBlock("button", _hoisted_20, _toDisplayString(_ctx.$t("list.onSale")), 1))
            ]),
            _createElementVNode("div", null, [
              (_ctx.token.isOnTrade)
                ? (_openBlock(), _createElementBlock("button", _hoisted_21, _toDisplayString(_ctx.$t("list.onTrade")), 1))
                : (_openBlock(), _createElementBlock("button", _hoisted_22, _toDisplayString(_ctx.$t("list.onTrade")), 1))
            ])
          ]))
        : _createCommentVNode("", true),
      (_ctx.token.salePrice > 0)
        ? (_openBlock(), _createElementBlock("p", _hoisted_23, _toDisplayString(_ctx.token.salePrice) + " ETH ", 1))
        : _createCommentVNode("", true),
      (_ctx.token.isOnTrade)
        ? (_openBlock(), _createElementBlock("div", _hoisted_24, [
            _createTextVNode(" [" + _toDisplayString(_ctx.$t("TokenSaleOrTrade.tradeForPrefecture")) + "] ", 1),
            _createElementVNode("div", _hoisted_25, _toDisplayString(_ctx.token.tradeToPrefecture
            .map((option) => _ctx.$t("prefecture." + _ctx.prefectureList[option]))
            .join(", ")), 1)
          ]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.size == 'L')
      ? (_openBlock(), _createElementBlock("div", _hoisted_26, [
          _createElementVNode("p", _hoisted_27, _toDisplayString(_ctx.$t("tokenDetail.owner")) + " : " + _toDisplayString(_ctx.token.holder), 1),
          (_ctx.OpenSeaPath)
            ? (_openBlock(), _createElementBlock("a", {
                key: 0,
                href: _ctx.OpenSeaPath,
                target: "_blank",
                class: "font-londrina font-yusei text-sm"
              }, "Opensea", 8, _hoisted_28))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true)
  ], 64))
}