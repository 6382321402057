
import { defineComponent, reactive, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { auth } from "@/utils/firebase";
import { User } from "firebase/auth";
import { ChainIdMap } from "@/utils/MetaMask";
import { useI18nParam } from "@/i18n/utils";

import Languages from "@/components/Languages.vue";
import Connect from "@/components/Connect.vue";
import { getAddresses } from "@/utils/const";
import { TWITTER_URL, DISCORD_URL } from "@/config/project";

interface UserData {
  user: User | null;
}

export default defineComponent({
  name: "AppLayout",
  props: {
    network: {
      type: String,
      required: true,
    },
    tokenAddress: {
      type: String,
      required: true,
    },
  },
  components: {
    Languages,
    Connect,
  },
  async setup(props) {
    const store = useStore();
    const route = useRoute();
    const user = reactive<UserData>({ user: null });
    useI18nParam();

    const displayMenu = computed(() => {
      if (
        route.path != "/" &&
        route.path != "/ja" &&
        route.path != "/en" &&
        route.path != "/ja/" &&
        route.path != "/en/"
      ) {
        return true;
      } else {
        return false;
      }
    });

    const displayWallet = computed(() => {
      if (
        route.path.includes("/terms") ||
        route.path.includes("/tokushoho") ||
        route.path.includes("/privacy") ||
        route.path.includes("/about")
      ) {
        return false;
      } else {
        return true;
      }
    });

    const chainId = computed(() => store.state.chainId);
    const networkChainId = ChainIdMap[props.network];

    onMounted(() => {
      auth.onAuthStateChanged((fbuser) => {
        console.log("authStateChanged:");
        if (fbuser) {
          user.user = fbuser;
          store.commit("setUser", fbuser);
        } else {
          store.commit("setUser", null);
        }
      });
    });

    const { OpenSeaPath, EtherscanToken } = getAddresses(
      props.network,
      props.tokenAddress,
    );

    return {
      user,
      OpenSeaPath,
      EtherscanToken,
      chainId,
      networkChainId,
      TWITTER_URL,
      DISCORD_URL,
      displayMenu,
      displayWallet,
    };
  },
});
