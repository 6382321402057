
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    limit: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const numOfMint = [...Array(Number(props.limit))].map((_, a) => a + 1);

    const selectedValue = ref(props.limit);

    const updateValue = (event: { target: HTMLSelectElement }) => {
      context.emit("update:modelValue", parseInt(event.target.value));
    };
    return {
      selectedValue,
      numOfMint,
      updateValue,
    };
  },
});
