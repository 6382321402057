
import { defineComponent, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { requestAccount, ChainIdMap, switchNetwork } from "@/utils/MetaMask";
import { auth } from "@/utils/firebase";
import { signInWithCustomToken } from "firebase/auth";
import { generateNonce, verifyNonce, deleteNonce } from "@/utils/functions";
import { ethers } from "ethers";
import {
  getNNSENSReverseResolverContract,
  resolveNNSorENS,
} from "@/utils/const";
//import Web3 from "web3";

export default defineComponent({
  props: {
    network: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const account = computed(() => store.state.account);
    const chainId = computed(() => store.state.chainId);
    const isSignedIn = computed(() => store.getters.isSignedIn);
    const isBusy = ref("");
    const networkChainId = ChainIdMap[props.network];
    const displayAccount = ref("");

    const provider = new ethers.BrowserProvider(store.state.ethereum);
    const resolverContract = getNNSENSReverseResolverContract(provider);

    const updateDisplayAccount = async () => {
      // NNSENSReverseResolverは今のところmainnetのみ存在
      if (props.network == "mainnet") {
        displayAccount.value = await resolveNNSorENS(
          resolverContract,
          account.value,
        );
      } else {
        displayAccount.value = account.value.substring(0, 6) + "...";
      }
    };

    // accountが変更されたらupdateDisplayAccountを実行するウォッチャー
    watch(account, async (newVal, oldVal) => {
      if (newVal !== oldVal) {
        updateDisplayAccount();
      }
      console.log("displayAccount.value", displayAccount.value);
    });

    const connect = async () => {
      isBusy.value = "Connecting Metamask...";
      try {
        await requestAccount(); // ethereum.on('accountsChanged') in App.vue will handle the result
      } catch (e) {
        console.log(e);
      }
      isBusy.value = "";
      console.log("*****", store.state.account);
      // signIn();
    };

    const switchNetworkChain = async () => {
      isBusy.value = "Switching to " + props.network + "...";
      try {
        await switchNetwork(networkChainId);
      } catch (e) {
        console.log(e);
      }
      isBusy.value = "";
      updateDisplayAccount();
      console.log("*****", props.network);
    };

    const signIn = async () => {
      const ethereum = store.state.ethereum;
      // Step 1: We get a nonce from the server
      isBusy.value = "Fetching a verification message from server...";
      const account = store.state.account;
      const result = (await generateNonce({ account })) as any;
      const nonce = result.data.nonce;
      const uuid = result.data.uuid;

      console.log("signIn: uuid/nonce", uuid, nonce);

      try {
        // Step 2: We ask the user to sign this nonce
        isBusy.value = "Waiting for you to sign a message...";
        const signature = await ethereum.request({
          method: "personal_sign",
          params: [nonce, account],
        });

        // Step 3: We ask the server to verify the signature and get custom token
        const result2 = (await verifyNonce({ signature, uuid })) as any;
        console.log(result2.data);
        const token = result2.data.token;
        console.log("signIn: token", token);
        if (token) {
          await signInWithCustomToken(auth, token);
        } else {
          alert("Failed to verifyIdenty");
        }
      } catch (e) {
        console.error(e);
        isBusy.value = "Canceling the verification...";
        try {
          await deleteNonce({ account, uuid });
        } catch (e) {
          console.error(e);
        }
      }
      isBusy.value = "";
    };
    const signOut = async () => {
      await auth.signOut();
    };
    const hasMetaMask = computed(() => {
      return store.getters.hasMetaMask;
    });

    return {
      hasMetaMask,
      account,
      displayAccount,
      chainId,
      networkChainId,
      isSignedIn,
      isBusy,
      connect,
      switchNetworkChain,
      signIn,
      signOut,
    };
  },
});
