import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "ml-16 font-londrina font-yusei text-xl" }
const _hoisted_2 = ["value", "id", "onChange", "disabled"]
const _hoisted_3 = ["for"]
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = {
  key: 0,
  class: "items-center"
}
const _hoisted_6 = {
  key: 1,
  class: "flex items-center"
}
const _hoisted_7 = { style: {"color":"grey"} }
const _hoisted_8 = { style: {"color":"grey"} }
const _hoisted_9 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.myTokens, (option, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: index,
        class: "flex items-center my-1"
      }, [
        _createElementVNode("input", {
          name: "selectMyToken",
          type: "radio",
          value: option,
          id: `option-${index}`,
          onChange: ($event: any) => (_ctx.updateValue($event, option.tokenId)),
          class: "mr-2",
          disabled: !option.canTrade
        }, null, 40, _hoisted_2),
        _createElementVNode("label", {
          for: `option-${index}`,
          class: "flex items-center"
        }, [
          (_openBlock(), _createElementBlock("svg", {
            innerHTML: option.svg,
            style: {"width":"40px","height":"40px"},
            class: "mx-1"
          }, null, 8, _hoisted_4)),
          (option.canTrade)
            ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                _createElementVNode("span", null, " #" + _toDisplayString(option.tokenId), 1),
                _createElementVNode("span", null, ", " + _toDisplayString(_ctx.$t("prefecture." + option.prefecture)), 1)
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, " #" + _toDisplayString(option.tokenId), 1),
                _createElementVNode("span", _hoisted_8, ", " + _toDisplayString(_ctx.$t("prefecture." + option.prefecture)), 1)
              ]))
        ], 8, _hoisted_3)
      ]))
    }), 128)),
    (!_ctx.hasTradableNouns)
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.$t("TokenSaleOrTrade.notHasTradableNouns")), 1))
      : _createCommentVNode("", true)
  ]))
}