import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-start w-full" }
const _hoisted_2 = { class: "flex items-center mb-4" }
const _hoisted_3 = { class: "ml-2" }
const _hoisted_4 = { class: "flex flex-wrap" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = ["value"]
const _hoisted_7 = { class: "ml-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createElementVNode("div", null, [
        _createElementVNode("div", _hoisted_2, [
          _withDirectives(_createElementVNode("input", {
            type: "checkbox",
            value: "0",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedValues) = $event)),
            onChange: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateValues && _ctx.updateValues(...args)))
          }, null, 544), [
            [_vModelCheckbox, _ctx.selectedValues]
          ]),
          _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.$t("prefecture.NotSpecified")), 1)
        ]),
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prefectureList, (option, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: index,
              class: "flex items-center mr-4 mb-2"
            }, [
              (option !== 'NotSpecified')
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _withDirectives(_createElementVNode("input", {
                      type: "checkbox",
                      value: index,
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedValues) = $event)),
                      onChange: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.updateValues && _ctx.updateValues(...args)))
                    }, null, 40, _hoisted_6), [
                      [_vModelCheckbox, _ctx.selectedValues]
                    ]),
                    _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("prefecture." + option)), 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ])
      ])
    ])
  ]))
}