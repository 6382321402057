
import { defineComponent, ref, watch, PropType } from "vue";
import { prefectureList } from "@/i18n/prefectures";

export default defineComponent({
  props: {
    initialPrefectures: {
      type: Array as PropType<number[]>,
      required: true,
    },
    modelValue: {
      type: Array as PropType<number[]>,
      required: true,
    },
  },
  setup(props, context) {
    const selectedValues = ref<number[]>(props.initialPrefectures);

    watch(
      () => props.modelValue,
      (newVal: number[]) => {
        selectedValues.value = newVal;
      },
    );

    const updateValues = () => {
      // 指定しない(0)が選ばれた場合は他をクリア
      if (selectedValues.value[selectedValues.value.length - 1] == 0) {
        selectedValues.value = [0];
      } else {
        // 指定しない(0)以外が選ばらた場合は0を削除
        selectedValues.value = selectedValues.value.filter(
          (item) => item !== 0,
        );
      }
      console.log(selectedValues.value);
      // context.emit("update:modelValue", selectedValues.value);
      context.emit("updateValues", selectedValues.value);
    };
    return {
      selectedValues,
      prefectureList,
      updateValues,
    };
  },
});
