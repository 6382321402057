
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { ethers } from "ethers";
import {
  getTokenContract,
  useFetchTokens,
  useMintConditions,
  getLocalNounsMinterContract,
  getTokenGate,
  useCheckTokenGate,
  getAddresses,
} from "@/utils/const";
import { ChainIdMap } from "@/utils/MetaMask";
import { weiToEther } from "@/utils/utils";
import Prefectures from "@/components/Prefectures.vue";
import NumOfMint from "@/components/NumOfMint.vue";
import FinishMintDialog from "@/components/FinishMintDialog.vue";
import ErrorDialog from "@/components/ErrorDialog.vue";
import { MINT_BY_CC_URL, MINT_NOT_SPECIFIED_BY_CC_URL } from "@/config/project";

export default defineComponent({
  props: {
    network: {
      type: String,
      required: true,
    },
    tokenAddress: {
      type: String,
      required: true,
    },
    // tokenGated: {
    //   type: Boolean,
    //   required: true,
    // },
    tokenGateAddress: {
      type: String,
      required: true,
    },
    // restricted: {
    //   type: String,
    // },
    assetProvider: {
      type: String,
    },
    minterAddress: {
      type: String,
      required: true,
    },
    // limit: {
    //   type: Number,
    // },
  },
  name: "Mint",
  components: {
    Prefectures,
    NumOfMint,
    FinishMintDialog,
    ErrorDialog,
  },
  setup(props) {
    const store = useStore();
    const i18n = useI18n();

    const isMinting = ref(false);
    const displayInformationDialog = ref(false);
    const mintedTokenId = ref(99999);
    const displayErrorDialog = ref(false);
    const errorDescription = ref("");
    const hashLink = ref("");
    const checkExplanation = ref(false);
    const checkTerms = ref(false);
    const checkTokushoho = ref(false);
    const checkPrivacy = ref(false);

    const lang = computed(() => {
      return i18n.locale.value;
    });

    const provider = new ethers.BrowserProvider(store.state.ethereum);

    // RO means read only.
    const contractRO = getTokenContract(props.tokenAddress, provider);
    // Minter
    const minterContract = getLocalNounsMinterContract(
      props.minterAddress,
      provider,
    );
    // TokenGate
    const tokenGateContract = getTokenGate(props.tokenGateAddress, provider);

    const { fetchTokens, totalSupply, tokens } = useFetchTokens(
      props.network,
      props.assetProvider,
      provider,
      contractRO,
    );
    fetchTokens();

    const {
      salePhase,
      mintLimit,
      mintPriceForSpecified,
      mintPriceForNotSpecified,
      mintConditions,
    } = useMintConditions(props.network, minterContract);

    mintConditions();

    // setInterval(() => {
    //   fetchTokens();
    // }, 30000); // 30秒ごとに実行

    const selectedPrefecture = ref(0);
    const selectedNumOfMint = ref(10);

    const mintPrice = computed(() => {
      if (selectedPrefecture.value == 0) {
        return weiToEther(mintPriceForNotSpecified.value);
      } else {
        return weiToEther(mintPriceForSpecified.value);
      }
    });

    const total = computed(() => {
      return mintPrice.value * Number(selectedNumOfMint.value);
    });

    const { balanceOf, checkTokenGate } = useCheckTokenGate(tokenGateContract);

    const account = computed(() => {
      checkTokenGate(store.state.account);
      return store.state.account;
    });

    const reload = async () => {
      await fetchTokens();
    };

    const mintByCCUrl = computed(() => {
      return `${MINT_BY_CC_URL}?quantity=${selectedNumOfMint.value}&recipientAddress=${account.value}`;
    });

    const mintNotSpecifiedByCCUrl = computed(() => {
      return `${MINT_NOT_SPECIFIED_BY_CC_URL}?prefectureId=${selectedPrefecture.value}&quantity=${selectedNumOfMint.value}&recipientAddress=${account.value}`;
    });

    const mint = async () => {
      const chainId = ChainIdMap[props.network];
      const signer = await store.getters.getSigner(chainId);

      const contract = getLocalNounsMinterContract(props.minterAddress, signer);

      isMinting.value = true;

      try {
        const weiValue = ethers.parseEther(total.value.toString());
        const txParams = { value: weiValue };
        const tx = await contract.mintSelectedPrefecture(
          selectedPrefecture.value,
          selectedNumOfMint.value,
          txParams,
        );

        const { EtherscanBase } = getAddresses(
          props.network,
          props.minterAddress,
        );
        hashLink.value = EtherscanBase + "/tx/" + tx.hash;

        const result = await tx.wait();

        // displayInformationDialog.value = true;
        console.log("mint:gasUsed", result.gasUsed);

        isMinting.value = false;
        displayInformationDialog.value = true;

        await fetchTokens();

        // await checkTokenGate(account.value);
      } catch (e) {
        console.error("mintSelectedPrefecture:", e);
        if (e instanceof Error) {
          errorDescription.value = "mintSelectedPrefecture:" + e.message;
        } else {
          errorDescription.value = "mintSelectedPrefecture:" + String(e);
        }
        const indexComma = errorDescription.value.indexOf("(");
        errorDescription.value = errorDescription.value.substring(
          0,
          indexComma,
        );
        if (errorDescription.value.indexOf("user rejected action") < 0) {
          displayErrorDialog.value = true;
        } else {
          isMinting.value = false;
        }
      }
    };

    const closeModal = () => {
      console.log("closeModal-reload");
      isMinting.value = false;
      displayInformationDialog.value = false;
      displayErrorDialog.value = false;
      hashLink.value = "";
    };

    return {
      lang,
      salePhase,
      mintPrice,
      totalSupply,
      mintLimit,
      balanceOf,
      tokens,
      total,
      selectedNumOfMint,
      selectedPrefecture,
      isMinting,
      displayInformationDialog,
      mintedTokenId,
      hashLink,
      displayErrorDialog,
      errorDescription,
      closeModal,
      account,
      mint,
      reload,
      checkExplanation,
      checkTerms,
      checkTokushoho,
      checkPrivacy,
      mintByCCUrl,
      mintNotSpecifiedByCCUrl,
    };
  },
});
