
import { defineComponent } from "vue";
import OwnerRanking from "@/components/OwnerRanking.vue";
import DailyRecordChart from "@/components/DailyRecordChart.vue";
import MonthlyRecordChart from "@/components/MonthlyRecordChart.vue";

export default defineComponent({
  props: {
    network: {
      type: String,
      required: true,
    },
    tokenAddress: {
      type: String,
      required: true,
    },
  },
  name: "Statistics",
  components: {
    OwnerRanking,
    DailyRecordChart,
    MonthlyRecordChart,
  },
  async setup() {},
});
